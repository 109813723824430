#topbar.topbar {
    /*position: fixed;*/
    position: relative;
    top: 0;
    /*left: 13%;*/
    left: 0;
    right: 0;
    /*min-height: 10px;*/
    text-align: right;
    background-color: $white;
    border-bottom: 1px solid $greyborder;
    padding: 10px 20px;
    z-index: 9995;

    .topbar_flex_end {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;

        /*div {
            width: 100%;
        }*/

        .app_name {
            text-align: left;
            padding: 0 0 0 0;
            font-size: 20px;
            font-weight: bold;
            width: 35%;
            display: flex;
            cursor:pointer;
            opacity: 1;
            transition: opacity .3s;

            .app_name_logo {

                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                font-size: 29px;
                width: 45px;
                height: 45px;
                text-align: center;
                color: $black;
                padding-top: 0;
                border: 3px solid $black;

            }
            .app_name_text {

                color: $black;
                padding-top: 10px;
                padding-left: 3px;
            }

            &:hover {
                opacity: 0.7;
                transition: opacity .3s;
            }
        }

        .app_search {
            width: 100%;
            text-align: center;

            input {
                text-align: center;
            }
        }

        .app_actions {
            width: 35%;
            display: flex;

            .user_logged {
                /*width: calc(35% - 70px);*/
                width: 100%;
                padding: 15px 10px 0 10px;
                text-align: right;
                font-size: 13px;
                border-right: 1px solid $greyborder;
    
                .app_logout {
                    padding: 0 5px;
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity .3s, color .3s;
    
                    &:hover {
                        opacity: 0.7;
                        color: #ff6060;
                        transition: opacity .3s, color .3s;
                    }
                }
            }
    
            .clients_list {
    
                padding: 13px 0 0 10px;
                width: 35px;
                cursor: pointer;
                opacity: 1;
                transition: opacity .3s;  
    
                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }
    
            /*.user_logged_admin {
                border-right: 1px solid $greyborder;
            }*/
    
            .users_manager {
                padding: 13px 0 0 10px;
                width: 35px;
                cursor: pointer;
                opacity: 1;
                transition: opacity .3s;  
    
                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }
    
            .users_params {
                padding: 13px 0 0 10px;
                width: 35px;
                cursor: pointer;
                opacity: 1;
                transition: opacity .3s;  
    
                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }

        }
    }
}