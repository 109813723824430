body.red {

    background-color: rgba(255, 103, 103, 0.5);

    .app_name {

        .app_name_logo {
            color: $themered !important;
            border: 3px solid $themered !important;
        }
        .app_name_text {
            color: $themered !important;
        }
    }

    .lds-ellipsis div {
        background: $themered !important;
    }

    input:checked + .slider {
        background-color: $themered !important;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px $themered !important;
    } 

    .table_pagination_item_selected {

        border: 1px solid $white;
        background-color: $themered !important;
        color: $white;
    }

    .table_sort {

        &:hover {
            color: $themered !important;
        }

        .table_sort_up {
            color: $themered !important;
        }
        .table_sort_down {
            color: $themered !important;
        }
    }
}