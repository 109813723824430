.user_params_container {
    position: relative;
    padding: 10px;

    .user_params_options {
        position: relative;
        display: flex;
        justify-content: flex-end;

        .option_params_item {
            padding: 5px;
            background-color: $white;
            border: 1px solid $greyborder;
            border-bottom: 0;
            font-size: 14px;
            margin-left: 5px;
            cursor: pointer;
            opacity: 1;
            transition: opacity .3s;

            &:hover {
                opacity: 0.7;
                transition: opacity .3s;
            }
        }

        .params_user_close {
            border: 1px solid $greyborder;
            border-bottom: 0;
            color: $white;
            background-color: $black;
        }
    }

    .user_params_content {
        position: relative;
        padding: 10px;
        background-color: $white;
        border: 1px solid $greyborder;
    }
}


.user_params_themes {
    display: flex;
    padding-top: 5px;

    .user_params_theme_item {
        padding: 5px;
        min-width: 50px;
        min-height: 50px;

        .theme_item_color {
            width: 30px;
            height:30px;
            cursor:pointer;
            border:1px solid $greyborder;

            &:hover {

                width: 40px;
                height:40px;
                margin-top: -5px;

            }
        }

        .theme_item_color_default {
            background-color: $black;
        }
        .theme_item_color_grey {
            background-color: $themegrey;
        }
        .theme_item_color_blue {
            background-color: $themeblue;
        }
        .theme_item_color_red {
            background-color: $themered;
        }
        .theme_item_color_orange {
            background-color: $themeorange;
        }
        .theme_item_color_green {
            background-color: $themegreen;
        }
        .theme_item_color_purple {
            background-color: $themepurple;
        }
        .theme_item_color_brown {
            background-color: $themebrown;
        }

        .theme_item_color_selected {
            /*width: 40px;
            height:40px;
            margin-top: -5px;*/
            -moz-box-shadow: 0px 0px 10px 0px #656565;
            -webkit-box-shadow: 0px 0px 10px 0px #656565;
            -o-box-shadow: 0px 0px 10px 0px #656565;
            box-shadow: 0px 0px 10px 0px #656565;
            filter:progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=NaN, Strength=10);
        }
    }
}