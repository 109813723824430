#login.login {
    position: relative;
    text-align:center;
    padding: 15% 0;
}

.login_app_name {
    position: relative;
    text-align: center;
    padding: 10px;
    background-color: $white;
    border: 1px solid $greyborder;

    .app_name {
        text-align: left;
        padding: 0 0 0 0;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;

        .app_name_logo {

            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            font-size: 29px;
            width: 45px;
            height: 45px;
            text-align: center;
            color: $black;
            padding-top: 0;
            border: 3px solid $black;

        }
        .app_name_text {
            color: $black;
            padding-top: 10px;
            padding-left: 3px;
        }
    }
}

.login_notice_error {
    position: relative;
    text-align: center;
    padding: 10px;
    color: $white;
    background-color: $noticeerrorbg;
    border: 1px solid $noticeerrorborder;
}

.remember_me_container {
    display:flex;

    .remember_me_text {
        padding: 5px 0 0 5px;
        color: $white;
    }
}
