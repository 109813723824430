.dashboard_flex {
    display: flex;
    flex-wrap: nowrap;

    .dashboard_flex_col {
        display:flex;
        flex-direction: column;
        width: 100%;

        .dashboard_flex_item {
            margin: 10px;
            min-height: 50px;
            background-color: $white;
            -moz-box-shadow: 0px 0px 5px 0px #c4c4c4;
            -webkit-box-shadow: 0px 0px 5px 0px #c4c4c4;
            -o-box-shadow: 0px 0px 5px 0px #c4c4c4;
            box-shadow: 0px 0px 5px 0px #c4c4c4;
            filter:progid:DXImageTransform.Microsoft.Shadow(color=#c4c4c4, Direction=NaN, Strength=5);
            display: none;
            padding:20px;
            text-align:center;
            position:relative;
            overflow: hidden;
            border-radius: 2px;
        }

        .dashboard_item_number {
            width:100%;
            padding: 5px;
            font-size: 32px;
        }

        .dashboard_link_abs {
            position:absolute;
            padding: 5px;
            bottom: 10px;
            right:10px;
        }

        .dashboard_link_rel {
            position:relative; 
            text-align: right;
            padding: 5px;
        }

        .dashboard_title {
            padding: 10px 5px;
            font-size:18px;
            font-weight:bold;
        }
        .dashboard_title_mb10 {
            padding: 10px 5px;
            font-size:18px;
            font-weight:bold;
            margin-bottom: 10px;
        }

        .dashboard_icon_100 {
            position: relative;
            font-size: 100px;
        }
        .dashboard_icon_48 {
            position: relative;
            font-size: 48px;
        }
        .dashboard_icon_28 {
            position: relative;
            font-size: 28px;
        }

        .dashboard_pd5 {
            width:100%;
            padding: 5px;
        }

        .dashboard_border_ddd {
            border-right:1px solid #ddd;
        }

        .dashboard_list_header {
            display:flex;
            border:1px solid #ddd;
            font-size: 13px;
            margin:2px;
        }

        .dashboard_list_content {
            display:flex;
            border:1px solid #ddd;
            font-size: 13px;
            margin:2px;
        }

        .dasboard_24b {
            font-size: 24px;
            font-weight:bold;
        }
        .dasboard_13 {
            font-size: 13px;
        }

        .dashboard_flex_center {
            display:flex;
            justify-content:center;
        }

        .dashboard_32 {
            font-size: 32px;
        }

        .dashboard_24_pd83 {
            font-size: 24px;
            padding: 8px 3px;
        }

        .dashboard_contrat_outdated {
            background-color: rgba(250, 17, 17, 0.4);
            color: $white;
        }
    }

    .app_user_params,
    .app_clients_list,
    .app_users_list
    {
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;  

        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
        }
    }
}