.user_edit_container {
    position: relative;
    padding: 10px;

    .user_edit_options {
        position: relative;
        display: flex;
        justify-content: flex-end;

        .option_edit_item {
            padding: 5px;
            background-color: $white;
            border: 1px solid $greyborder;
            border-bottom: 0;
            font-size: 14px;
            margin-left: 5px;
            cursor: pointer;
            opacity: 1;
            transition: opacity .3s;

            &:hover {
                opacity: 0.7;
                transition: opacity .3s;
            }
        }
    }

    .user_edit_content {
        position: relative;
        padding: 10px;
        background-color: $white;
        border: 1px solid $greyborder;
    }
}