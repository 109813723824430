.client_edit_container {
    position: relative;
    padding: 10px;

    .client_edit_options {
        position: relative;
        display: flex;
        justify-content: flex-end;

        .option_edit_item {
            padding: 5px;
            background-color: $white;
            border: 1px solid $greyborder;
            border-bottom: 0;
            font-size: 14px;
            margin-left: 5px;
            cursor: pointer;
            opacity: 1;
            transition: opacity .3s;

            &:hover {
                opacity: 0.7;
                transition: opacity .3s;
            }
        }
    }

    .client_edit_content {
        position: relative;
        padding: 10px;
        background-color: $white;
        border: 1px solid $greyborder;
    }
}

.client_comment_content {

    position: relative;
    background-color: white;
    padding:0;
    margin: 0;
    border:1px solid #ddd;

}

.client_history_content {

    position: relative;
    background-color: white;
    padding:0;
    margin: 0;
    border:1px solid #ddd;

    .client_history_years_content {
        display:flex;

        .client_history_years_item {
            width:100%;
            border-left:1px solid #ddd;
            border-bottom:1px solid #ddd;
            padding: 10px;
            text-align: center;
            cursor: pointer;
            opacity: 0.5;
        }

        .client_history_years_item:first-child {
            border:0;
            border-bottom:1px solid #ddd;
        }
        .client_history_years_item_selected {
            opacity: 1;
            border-bottom:0 !important;
        }
    }

    .client_history_datas_content {
        position: relative;

        .client_history_datas_items {
            position:relative;

            .client_history_datas_item {
                display:none;

                .history_datas_item_content {

                    display: flex;

                    .history_datas_item {
                        width:100%;
                        border:1px solid #ddd;
                        border-left: 0;
                        border-top: 0;
                        padding:3px;
                    }

                    .history_datas_negative {

                        width: auto;
                        text-align: center;
                        padding: 10px;
                    }

                }
            }
        }

        .history_datas_item_header {

            display: flex;

            .history_datas_item {
                width:100%;
                border:1px solid #ddd;
                border-left: 0;
                padding:10px 3px;
            }

            .history_datas_negative {

                width: auto;
                text-align: center;
                padding: 10px;
            }

        }
    }
}