body {
    margin: 0;
    padding: 0;
    background-color: rgba(44, 44, 44, 0.6);
    font-family: 'Dosis', sans-serif;
    font-size: 16px;
    color: $black;
}

.optimized_app_notice {
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: 11px;
    padding: 5px;
    background-color: $white;
}

.scroll_to_top {
    position: fixed;
    /*top: 50%;
    right: 9px;*/
    bottom: 10px;
    left: 7px;
    padding: 5px;
    border: 2px solid $white;
    background-color: transparent;
    color: $white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 11px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity .3s;
    display: none;

    &:hover {
        opacity: 0.5;
        transition: opacity .3s;
    }
}


a, a:link, a:active, a:focus, a:visited {
    color: inherit;
    text-decoration: underline;
    opacity: 1;
    transition: opacity .3s;

    &:hover {
        opacity: 0.5;
        transition: opacity .3s;
    }
}


.view_content_close,
.client_edit_close,
.user_edit_close,
.user_params_close    
{
    border: 1px solid $greyborder;
    border-bottom: 0;
    color: $white;
    background-color: $black !important;
}



*, *:before, *:after {
    box-sizing: border-box;
}


@media (max-width: 1290px) {

    .app_user_trait,
    .app_user_role {
        display: none;
    }
}


@media (max-width: 1225px) {

    .dashboard_flex {
        flex-wrap : wrap;
        justify-content: center;

        .dashboard_flex_col {
            flex-direction: row;
            flex-wrap : wrap;
            justify-content: center;
            align-items: center;
            
            .dashboard_flex_item {
                width: 45%;
            }
        }
    }

    .form_notice {
        right: 0 !important;
        border-radius: 0 !important;
        text-align: center !important;
    }
}


@media (max-width: 1180px) {

    table {
        border: 0;
        table-layout: fixed;
    }
      
    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table thead tr th {
        border-right: #ddd;
    }

    
      
    table tr {
        /*border-bottom: 3px solid #ddd;*/
        display: block;
        border-bottom: 20px solid #ddd;
        margin-bottom: .625em;

        td.client_data_key, td.client_data_created_at {
            display: none !important;
        }
    }
      
    table td {
        /*border-bottom: 1px solid #ddd;*/
        display: block !important;
        font-size: 13px;
        text-align: left !important;
        height: inherit !important;
    }
      
    table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        /*text-transform: uppercase;*/
        width: 50%;
        text-align: left !important;
    }
      
      table td:last-child {
        border-bottom: 0;
      }
}

@media (max-width: 1075px) {

    .app_username {
        display: none;
    }
}


@media (max-width: 885px) {

    .dashboard_flex {

        .dashboard_flex_col {
            
            .dashboard_flex_item {
                width: 100%;
            }
        }
    }
}

@media (max-width: 700px) {

    #topbar.topbar {

        .topbar_flex_end {

            .app_name,
            .app_actions {
                width: 50%;
            }

            .app_actions {

                .users_manager,
                .clients_list,
                .users_params {

                    margin-left: 15px;

                }

            }
        }
    }

    .app_search {
        display: none;
    }

    form {
        div {
            display:flex;
            flex-wrap: wrap;
            width: 100% !important;

            div {
                width: 100% !important;
            }
        }

        div.user_params_themes {
            width: auto !important;
            display:flex;
            flex-wrap: wrap;

            div.user_params_theme_item {
                width: auto !important;
            }

            div.theme_item_color {
                width: 40px !important;
            }
        }
    }
}