body.green {

    background-color: rgba(114, 231, 129, 0.5);

    .app_name {

        .app_name_logo {
            color: $themegreen !important;
            border: 3px solid $themegreen !important;
        }
        .app_name_text {
            color: $themegreen !important;
        }
    }

    .lds-ellipsis div {
        background: $themegreen !important;
    }

    input:checked + .slider {
        background-color: $themegreen !important;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px $themegreen !important;
    }  


    .table_pagination_item_selected {

        border: 1px solid $white;
        background-color: $themegreen !important;
        color: $white;
    }


    .table_sort {

        &:hover {
            color: $themegreen !important;
        }

        .table_sort_up {
            color: $themegreen !important;
        }
        .table_sort_down {
            color: $themegreen !important;
        }
    }
}