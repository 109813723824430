.table_options {
    position: relative;
    display: flex;
    justify-content: flex-end;

    .option_item {
        padding: 5px;
        background-color: $white;
        border: 1px solid $greyborder;
        border-bottom: 0;
        font-size: 14px;
        margin-left: 5px;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;

        &:hover {
            opacity: 0.7;
            transition: opacity .3s;
        }
    }

    .option_total_items {
        font-size: 13px;
    }

    .option_form_add_open {

        i {
            transform: rotate(45deg);
        }
    }

    .option_notice {
        display:none;
        padding: 5px 30px;
        font-size: 13px;
    }
}
.table_container {
    position: relative;
    padding: 10px;
    /*margin-bottom: 40px;*/

    .users_content_close {
        border: 1px solid $greyborder;
        border-bottom: 0;
        color: $white;
        background-color: $black;
    }
}

table.table_app {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
    background-color: white;

    th {
        height: 65px;
        position:relative;
        overflow: hidden;
    }
    
    td {
        height: 30px;
    }
    
    th, td {
        padding: 10px 15px;
        text-align: left;
    }
    
    tr:nth-child(even) {
        background-color: #f0f0f0;
    }

    tr:nth-child(odd) {
        background-color: $white;
    }

    .inline {
        margin: 0 5px;
        font-size: 11px;
        padding: 5px;
        display: inline;
        border: 1px solid grey;
        background-color: grey;
        color: white;

        border-radius: 3px;
    }

    .cel-center {
        text-align: center;
    }

    .table_sort {
        position: absolute;
        overflow: hidden;
        right: 0;
        bottom:0;
        padding: 1px 4px;
        cursor: pointer;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        opacity: 1;
        transition: opacity .3s;

        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
            color: $black;
        }

        .table_sort_up {
            display:none;
            color: $black;
        }
        .table_sort_down {
            display:none;
            color: $black;
        }
    }

    .table_item_btn {
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;

        &:hover {
            opacity: 0.6;
            transition: opacity .3s;
        }
    }

    .volume_diff_negative,
    .date_diff_negative,
    .user_is_inactive 
    {
        background-color: rgba(250, 17, 17, 0.4);
        color: $white;
    }

    .client_sync_anim {

        -webkit-animation: spin 1s linear infinite; 
        -moz-animation:    spin 1s linear infinite; 
        -o-animation:      spin 1s linear infinite; 
        animation:         spin 1s linear infinite;
    }
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

table.table_app, th, td {
    border: 1px solid $greyborder;
}

.mini_ball {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    margin: auto;
}

.mini_ball_green {
    background-color : $noticesuccessbg;
}

.mini_ball_red {
    background-color : $noticeerrorbg;
}

.mini_ball_transparent {
    background-color : transparent;
}




.table_action{
    cursor: pointer;
}

.table_action_selected {
    display: none;
    padding: 5px 0;

    .table_action_flex {
        display: flex;
        justify-content: flex-start;

        .tale_action_flex_item_90p{
            width: 90%;
        }

        .tale_action_flex_item_10p{
            width: 10%;
        }
    }
}


.table_filter_container {
    padding-bottom: 3px;
}

.table_filter_item {
    padding-right: 10px;
}

.table_search_field_container {

    position:relative;
    margin:0;

    .table_search_field {
        width:100%;
        padding:5px;
        font-family:'Dosis',sans-serif;
        font-size:14px;
        border:1px solid #d1cfcf;
        border-bottom: 0;
    }

}

.table_top_filters_container {
    display:flex;
    justify-content:end;
    position: relative;
    overflow: visible;

    .table_filter_notice {
        position: absolute;
        right: 1px;
        padding: 10px 10px;
        border-left: 1px solid #d1cfcf;
        border-bottom: 1px solid #d1cfcf;
        top: 34px;
        background-color: white;
        display: none;
        z-index: 50;
    }

    .table_filter_item {
        border:1px solid #d1cfcf;
        padding: 5px 10px;
        margin: 2px 0 0 3px;
        border-bottom: 0;
        cursor: pointer;
        transition: opacity 0.3;
        max-height: 33px;

        &:hover {
            opacity: 0.5;
            transition: opacity 0.3;
        }
    }

    .table_multi_delete {
        display: none;
        margin-left: 20px;
    }

    .table_filter_item_per_page_container {
        display:flex;
        padding: 0;
        border: 0;
        margin-left: 20px;
    }

    .table_filter_item_per_page_plus {

        border: 1px solid #BABABA;
        padding: 0 10px;
        i{
            font-size: 10px;
        }
        border-bottom: 0;
        padding-top: 3px;
    }

    .table_filter_item_per_page_minus {

        border: 1px solid #BABABA;
        padding: 0 10px;
        i{
            font-size: 10px;
        }
        border-bottom: 0;
        padding-top: 3px;
    }

    .table_filter_item_per_page_field {
        border-top: 1px solid #BABABA;
        border-bottom: 0;
    }

    input[type="number"].table_filter_item_per_page {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
            appearance: textfield;
        width: 40px;
        height: 25px;
        text-align: center;
        border: 0;
        font-size: 12px;
    }
    input[type=number].table_filter_item_per_page::-webkit-inner-spin-button, 
    input[type=number].table_filter_item_per_page::-webkit-outer-spin-button { 
        -webkit-appearance: none;
    }
}

.item_move_grip {
    cursor: pointer;
    opacity: 1;
    transition: opacity .5s;
    &:hover {
        opacity: 0.5;
        transition: opacity .5s;
    }
}

.bg_active {
    td {
        background-color : #effdee;
    }
}

.bg_draft {
    td {
        background-color : #feffea;
    }
}

.bg_inactive {
    td {
        background-color : #fcecec;
    }
}

.bg_closed {
    td {
        background-color : #eaefff;
    }
}


.table_app_pag {

    tbody > tr {
        display: none;
    }
}

.table_pagination_page {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 5px;

    .table_pagination_item {
        padding: 5px;
        border: 1px solid $greyborder;
        background-color: $white;
        margin: 0 3px;
        cursor: pointer;
        font-size: 12px;
        min-width: 30px;
        text-align: center;
    }

    .table_pagination_item_selected {

        border: 1px solid $white;
        background-color: $black;
        color: $white;
    }

    .table_pagination_item_hidden {
        display: none;
    }

    .table_pagination_item_next,
    .table_pagination_item_prev 
    {

        padding: 5px;
        border: 1px solid $greyborder;
        background-color: $white;
        margin: 0 3px;
        cursor: pointer;
        font-size: 12px;
        min-width: 30px;
        text-align: center;

    }
}

.table_pagination_items_number {
    font-size: 13px;
    width: 50px;
    padding: 0 3px;
    text-align: center;
    border: 0;
}