$black : #2c2c2c;
$greyborder: #ddd;
$white : #fff;
$noticeerrorbg : #e25959;
$noticeerrorborder: #f80000;
$noticesuccessbg: #67b96b;
$noticesuccessborder: #005804;

$themegrey: #BABABA;
$themeblue: #46a5ff;
$themered: #ff6767;
$themeorange: #ffab67;
$themegreen: #72e781;
$themepurple: #c876ff;
$themebrown: #917762;