.modal {
    position: fixed;
    top: 20%;
    border: 1px solid $greyborder;
    background-color: $white;
    z-index: 888;
    display: none;
    -moz-box-shadow: 0px 0px 10px 0px #656565;
    -webkit-box-shadow: 0px 0px 10px 0px #656565;
    -o-box-shadow: 0px 0px 10px 0px #656565;
    box-shadow: 0px 0px 10px 0px #656565;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=NaN, Strength=10);

    .modal_flex_container {
        display:flex;

        .modal_flex_item_100 {
            width:100%;
            padding: 5px;
        }
    }
}

.modal_confirm {

    width: 300px;
    left: calc( 50% - 150px );
    padding: 20px;

    .modal_header {
        padding: 5px;
        font-size: 22px;
        font-weight: bold;
        text-align:center;
    }
}