input,select,textarea {
    width: 100%;
    border: 1px solid $greyborder;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Dosis', sans-serif;
    font-size: 16px;
}

button {
    width: 100%;
    border: 1px solid $greyborder;
    padding: 10px;
    background-color: $white;
    box-sizing: border-box;
    font-family: 'Dosis', sans-serif;
    font-size: 16px;
    cursor:pointer;
    opacity: 1;
    transition: opacity .3s;  

    &:hover {
        opacity: 0.5;
        transition: opacity .3s;
    }
}

.field_container {
    margin: 10px 0;
    position:relative;
}

.field_login {
    width: 20%;
    margin: 10px auto;
    position: relative;
    text-align: left;
}

.form_notice {
    /*position: relative;*/
    text-align: center;
    padding: 30px 20px;
    /*margin: 10px 0;*/
    display:none;
    position: fixed;
    left: 0;
    right: 60%;
    /*bottom: 45%;*/
    bottom: 10px;
    z-index: 888;
    overflow: hidden;
    text-align: left;
    min-width: 400px;

    -webkit-border-radius : 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    opacity: 0;

    -moz-box-shadow: 0px 0px 10px 0px #656565;
    -webkit-box-shadow: 0px 0px 10px 0px #656565;
    -o-box-shadow: 0px 0px 10px 0px #656565;
    box-shadow: 0px 0px 10px 0px #656565;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=NaN, Strength=10);

    .form_notice_close {

        position: absolute;
        top: 2px;
        right: 5px;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;
        font-size: 20px;

        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
        }
    }

    .form_notice_title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.form_notice_visible {

    -webkit-animation: slide_right .5s ease-in;
    -moz-animation: slide_right .5s ease-in;
    animation: slide_right .5s ease-in;
    animation-fill-mode: forwards;
}


@keyframes slide_right {
    0% { opacity: 0; -moz-transform: translateX(-100%); }   
  100% { opacity: 1; -moz-transform: translateX(0); }
}

@-webkit-keyframes slide_right {
    0% { opacity: 0; -webkit-transform: translateX(-100%); }   
  100% { opacity: 1; -webkit-transform: translateX(0); }
}
@-moz-keyframes slide_right {
    0% { opacity: 0; -moz-transform: translateX(-100%); }   
  100% { opacity: 1; -moz-transform: translateX(0); }
}


.form_container_alert {
    /*border: 1px solid $noticeerrorborder;*/
    background-color : $noticeerrorbg;
    color: $white;
    margin-top: 10px;
    border-radius: 4px;
}

.form_container_is_good {
    /*border: 1px solid $noticesuccessborder;*/
    background-color : $noticesuccessbg;
    color: $white;
    margin-top: 10px;
    border-radius: 4px;
}


.form_notice_error {
    background-color: $noticeerrorbg;
    border: 0;
    color: $white;
    /*margin: 5px 10px;*/
}

.form_notice_success {
    background-color: $noticesuccessbg;
    border: 0;
    color: $white;
    /*margin: 5px 10px;*/
}


.form_add_user,
.form_add_client {
    border:1px solid $greyborder;
    background-color: $white;
    display:none;
    margin-bottom: 20px;
}

.form_flex {
    display: flex;
}
.form_flex_item_100 {
    width: 100%;
    padding: 10px;
}
.form_flex_item_50 {
    width: 50%;
    padding: 10px;
}



/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
  
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: $black;
}

input:focus + .slider {
    box-shadow: 0 0 1px $black;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}