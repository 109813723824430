.error_container {
    position: relative;
    width: 1024px;
    margin: auto auto;
    text-align: center;

    .error_content {
        background-color: #fff;
        padding: 10px 30px;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px;
    }
}

.error_app_name {
    position: relative;
    text-align: center;
    padding: 10px;
    background-color: $white;
    border: 1px solid $greyborder;
    margin-bottom: 10px;
    margin-top: 15%;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;

    .app_name {
        text-align: left;
        padding: 0 0 0 0;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;

        .app_name_logo {

            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            font-size: 29px;
            width: 45px;
            height: 45px;
            text-align: center;
            color: $black;
            padding-top: 0;
            border: 3px solid $black;

        }
        .app_name_text {
            color: $black;
            padding-top: 10px;
            padding-left: 3px;
        }
    }
}