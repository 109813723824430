#sidebar.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    /*width: 13%;*/
    width: 0;
    border-right: 1px solid $greyborder;
    height: 100%;
    background-color : $white;
}